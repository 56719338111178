<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Academic Years</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active">Academic Years</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <router-link v-can="'add_academic_years'" :to="{ name: 'Academic-Years-Create' }" class="btn btn-primary">
              Add New</router-link>
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <!-- form will be here -->
              </div>
            </div>
            <div class="table-responsive">
              <table class="
                  table table-bordered table-striped
                  verticle-middle
                  table-responsive-sm
                ">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Terms</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody v-if="years.length">
                  <tr v-for="year in years" :key="year.id">
                    <td>{{ year.value }}</td>
                    <td>{{ year.start_date }}</td>
                    <td>{{ year.end_date }}</td>
                    <td>
                      <span class="gray-tag" v-for="term in year.terms" :key="term.id">
                        {{ term.name }}
                      </span>
                    </td>

                    <td>
                      <span>
                        <a v-can="'add_academic_years'" @click.prevent="cloneYear(year.id)" href="javascript:void()"
                          title="Clone" class="mr-4">
                          <i class="fa fa-clipboard" aria-hidden="true"></i>
                        </a>

                        <router-link :to="{
                          name: 'Academic-Years-Subjects',
                          params: { year_id: year.id },
                        }" title="Subjects" class="mr-4"><i class="fa fa-book color-muted"></i>
                        </router-link>

                        <router-link :to="{
                          name: 'Academic-Years-Single',
                          params: { id: year.id },
                        }" title="View" class="mr-4"><i class="fa fa-eye color-muted"></i>
                        </router-link>
                        <router-link v-can="'edit_academic_years'" :to="{
                          name: 'Academic-Years-Edit',
                          params: { id: year.id },
                        }" title="Edit" class="mr-4"><i class="fa fa-pencil color-muted"></i>
                        </router-link>
                        <a v-can="'delete_academic_years'" @click.prevent="deleteData(year.id)" href="javascript:void()"
                          title="Delete"><i class="fa fa-close color-danger"></i></a>

                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="6">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAcademicYears from "@/composables/academic_years";
import { inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_academic_years")) {
        router.push({
          name: "401",
        });
      } else {
        getAcademicYears();
      }
    });

    const { years, cloneAcademicYear, getAcademicYears, deleteAcademicYear } = useAcademicYears();

    const deleteData = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteAcademicYear(id).then(() => {
              swal.fire(
                "Deleted!",
                "The data has been deleted successfully.",
                "success"
              );
              getAcademicYears();
            });
          }
        });
    };

    const cloneYear = async (id) => {
      swal
        .fire({
          title: "Are you sure you want to clone this academic year setting?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, clone it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {

            swal.fire({
              title: "Please wait while submit your request",
              allowOutsideClick: false,
              didOpen: () => {
                swal.showLoading();
              },
            });
            
            await cloneAcademicYear(id).then((resp) => {
              swal.close();
              if (resp != "fail") {
                router.push({
                  name: 'Academic-Years-Edit',
                  params: { id: resp, },
                });
              }
            });
          }
        });
    };

    return {
      years,
      deleteData,
      cloneYear
    };
  },
};
</script>
